import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


import {
	Flex,
	Heading,
	Block,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody, Modal } from '../../../components/layouts'

import {
	Image,
	Link,
	Loader,
	Button,
	Input,
	AccountItem
} from '../../../components/ui'

import {
	logout, getUserProfile, setDonationAccount, setDonationType, deleteAccount, setMonthlyMax, setMonthlyMin, trackAccountForRoundups
} from '../../../factory'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'

const donorTypes = [
	{text: "I don't want to give right now", value: 0},
	{text: 'Round Up Donations', value: 1},
	{text: 'Daily Donations', value: 3},
	{text: 'Round up + Daily Donations', value: 2},
]

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			monthly_max: null,
			monthly_min_donation_limit: null,
			daily_donation: null
		}

		this.onChange = this.onChange.bind(this)
		this.onChangeDonorType = this.onChangeDonorType.bind(this)
		this.onCheckRoundUps = this.onCheckRoundUps.bind(this)
		this.selectFundingSource = this.selectFundingSource.bind(this)
	}

	componentDidMount() {
		this.props.getUserProfile(this.props.token)
	}

	onChange(e) {
		const edited = [e.target.name] + "_edited"
		this.setState({[e.target.name]: e.target.value, [edited]: true})
	}

	onChangeDonorType(e) {
		const donor_type = e.target.value
		let daily_donation_sum = 0
		if (donor_type > 1) {
			daily_donation_sum = 0.4
		}
		const params = {
			donor_type,
			previous_type: this.props.donor_type,
			daily_donation_sum
		}

		this.props.setDonationType(params, this.props.token)
	}

	onSaveDailyDonation() {
		const {donor_type} = this.props
		const params = {
			donor_type,
			daily_donation_sum: this.state.daily_donation
		}

		this.props.setDonationType(params, this.props.token)
		this.setState({daily_donation_edited: false})
	}

	onCheckRoundUps(e) {
		this.props.trackAccountForRoundups(parseInt(e.target.value, 10), e.target.checked, this.props.token)
	}

	selectFundingSource(e) {
		const {accountList, token} = this.props
		let acc_id = parseInt(e.target.value, 10)
		let value = true
		const paymentAccountId = accountList.filter((acc) => acc.is_payment).length > 0 ? accountList.filter((acc) => acc.is_payment)[0].account_id : -1

		if (acc_id === -1) {
			acc_id = paymentAccountId
			value = false
		}

		this.props.setDonationAccount(acc_id, value, token)
	}

	render() {
		const {user, monthly_min_donation_limit, month_donation_limit, daily_donation, accountList, profile_pending, donor_type, profile_error, bank_pending} = this.props
		const paymentAccountId = accountList.filter((acc) => acc.is_payment).length > 0 ? accountList.filter((acc) => acc.is_payment)[0].account_id : -1
		const accounts = accountList.map((acc) => {return {value: acc.account_id, text: `${acc.name} ${acc.mask}`, is_chargable: acc.is_chargable, is_linked: acc.is_linked}})
		const hasChargeable = accountList.filter((acc) => acc.is_chargable).length > 0

		if (!user) {
			return (
				<Layout>
					<Container>
						<PhoneBody title={'My Profile'}>
							<Flex column style={{background: '#F2F5F5', width: '100%', paddingLeft: 20, paddingTop: 20, paddingBottom: 10, color: theme.colors.dustGray}}>
								<Paragraph marginBottom={3} >Hello</Paragraph>
							</Flex>
						</PhoneBody>
					</Container>
				</Layout>
			)
		}

		return (
			<Layout>
				<Container>
					<PhoneBody title={'My Profile'}>
						{profile_error && <Flex column style={{background: theme.colors.crusta, width: '100%', paddingLeft: 20, paddingTop: 20, paddingBottom: 10, color: theme.colors.dustGray}}>
							<Paragraph marginBottom={3} color={'white'}>{profile_error}</Paragraph>
						</Flex>}
						<Flex column style={{background: '#F2F5F5', width: '100%', paddingLeft: 20, paddingTop: 20, paddingBottom: 10, color: theme.colors.dustGray}}>
							<Paragraph marginBottom={3} >Hello</Paragraph>
							<Heading marginTop={0} marginBottom={10} fontSize={50} color={'black'} lineHeight={'0.8em'}>{user.first_name}</Heading>
						</Flex>
						<div style={{paddingLeft: 20, height: '100%', marginTop: 20}}>
							<Heading as="h4" fontSize={16} marginBottom={3}>My Profile</Heading>
							<Paragraph marginBottom={0}>{user.first_name} {user.last_name}</Paragraph>
							<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Email</Heading>
							<Paragraph marginBottom={0}>{user.email}</Paragraph>
							<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Phone</Heading>
							<Paragraph marginBottom={0}>{user.phone}</Paragraph>
							<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Monthly Donation Limit</Heading>
							<Flex row style={{width: '100%'}}>
								<div style={{height: '2em', width: '50%', marginBottom: 25}}>
									<Input
										label={'$ Amount'}
										name={'monthly_max'}
										field={'default'}
										placeholder={'$100'}
										type={'text'}
										maxWidth={'400px'}
										value={this.state.monthly_max !== null ? this.state.monthly_max : month_donation_limit}
										setValues={this.onChange}
									/>
								</div>
								{this.state.monthly_max_edited && <Button
									onClick={()=>{this.props.setMonthlyMax(this.state.monthly_max, this.props.token); this.setState({monthly_max_edited: false})}}
									style={{width: 40, margin: 'auto 0px', marginLeft: "10px", }}>
									<Image
										src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/saveIcon.svg"}
										height={'25px'}
										width={'25px'}
										marginBottom={'auto'}
										marginTop={'auto'}
									/>
								</Button>}
							</Flex>
							<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Minimum Monthly Donation</Heading>
							<Flex row style={{width: '100%'}}>
								<div style={{height: '2em', width: '50%', marginBottom: 25}}>
									<Input
										label={'$ Amount'}
										name={'monthly_min_donation_limit'}
										field={'default'}
										placeholder={'$100'}
										type={'text'}
										maxWidth={'400px'}
										value={this.state.monthly_min_donation_limit !== null ? this.state.monthly_min_donation_limit : monthly_min_donation_limit}
										setValues={this.onChange}
									/>
								</div>
								{this.state.monthly_min_donation_limit_edited && <Button
									onClick={()=>{this.props.setMonthlyMin(this.state.monthly_min_donation_limit, this.props.token); this.setState({monthly_min_donation_limit_edited: false})}}
									style={{width: 40, margin: 'auto 0px', marginLeft: "10px", }}>
									<Image
										src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/saveIcon.svg"}
										height={'25px'}
										width={'25px'}
										marginBottom={'auto'}
										marginTop={'auto'}
									/>
								</Button>}
							</Flex>
							<Flex row justifyContent={'space-between'}>
								<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Donation Method</Heading>
								<Modal
									style={{display: 'flex', alignItems: 'center', }}
									containterStyle={{display: 'flex', flexDirection: 'column', width: "90%"}}
									button={{text: 'i', width: '25px', margin: 'auto', marginRight: 25, fontSize: 12, background: 'white', border: "2px solid #ffcc33"}}
								>
									<Paragraph style={{paddingRight: 50}}>We want to give you the most flexible giving experience so we offer all kinds of giving options.
										<br/> 1. You can give through roundups (rounding up your transaction to the nearest dollar and giving the difference),
										<br/> 2. Daily donations
										<br/> 3. Both round ups and daily donations!
										<br/><br/>You can always make a one time donation on the “Your Impact” tab as well.</Paragraph>
								</Modal>
							</Flex>
							<div style={{height: '2em', width: '85%', marginBottom: 25}}>
								<Input
									label={'Account'}
									name={'payment_account'}
									field={'select'}
									options={donorTypes}
									placeholder={'$100'}
									maxWidth={'400px'}
									defaultValue={donor_type}
									setValues={this.onChangeDonorType}
								/>
							</div>
							{donor_type > 1 && <div style={{marginLeft: 25}}>
								<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Daily Donation</Heading>
								<Flex row style={{width: '100%'}}>
									<div style={{height: '2em', width: '50%', marginBottom: 25}}>
										<Input
											label={'$ Amount'}
											name={'daily_donation'}
											field={'default'}
											placeholder={'$0.4'}
											type={'text'}
											maxWidth={'400px'}
											value={this.state.daily_donation !== null ? this.state.daily_donation : daily_donation}
											setValues={this.onChange}
										/>
									</div>
									{this.state.daily_donation_edited && <Button
										onClick={()=>this.onSaveDailyDonation()}
										style={{width: 40, margin: 'auto 0px', marginLeft: "10px", }}>
										<Image
											src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/saveIcon.svg"}
											height={'25px'}
											width={'25px'}
											marginBottom={'auto'}
											marginTop={'auto'}
										/>
									</Button>}
								</Flex>
							</div>
							}

							<Flex row justifyContent={'space-between'}>
								<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Funding Source</Heading>
								<Modal
									style={{display: 'flex', alignItems: 'center', }}
									containterStyle={{display: 'flex', flexDirection: 'column', width: "90%"}}
									button={{text: 'i', width: '25px', margin: 'auto', marginRight: 25, fontSize: 12, background: 'white', border: "2px solid #ffcc33"}}
								>
									<Paragraph style={{paddingRight: 50}}>The listed account is the account from which we with withdraw your donations. We can process donations either from a credit card or a checking account.</Paragraph>
								</Modal>
							</Flex>
							<div style={{height: '2em', width: '85%', marginBottom: 25}}>
								<Input
									label={'Account'}
									name={'payment_account'}
									field={'select'}
									options={accounts.filter((acc) => acc.is_chargable && acc.is_linked && acc).concat([{value: -1, text: "I don't want to give right now"}])}
									placeholder={'$100'}
									type={'text'}
									maxWidth={'400px'}
									defaultValue={paymentAccountId}
									setValues={this.selectFundingSource}
								/>
							</div>

							<Flex row justifyContent={'space-between'}>
								<Heading as="h4" fontSize={16} marginBottom={3} marginTop={10}>Linked Accounts</Heading>
								<Modal
									style={{display: 'flex', alignItems: 'center', }}
									containterStyle={{display: 'flex', flexDirection: 'column', width: "90%"}}
									button={{text: 'i', width: '25px', margin: 'auto', marginRight: 25, fontSize: 12, background: 'white', border: "2px solid #ffcc33"}}
								>
									<Paragraph style={{paddingRight: 50}}>This is your list of linked credit cards and bank accounts. If there are checkboxes next to an account, we can track it for roundups!</Paragraph>
								</Modal>
							</Flex>
							<Paragraph style={{margin: "0px 0px 5px 25px", fontSize: 10, lineHeight: '1em'}}> Press the yellow box to toggled round ups on and off</Paragraph>
							<Paragraph style={{margin: 0, marginLeft: "25px", marginBottom: '10px', fontSize: 10, lineHeight: '1em', }}> Press the "X" to remove the account from flourish</Paragraph>
							{accountList.length > 0 && accountList.map((item) => (<AccountItem
								onCheck={this.onCheckRoundUps}
								pending={bank_pending}
								onDeleteAccount={(id)=>this.props.deleteAccount(id, this.props.token, () => typeof(window) !== 'undefined' && window.location.reload())}
								account={item}/>))}
							<Link href={`/admin/profile/linkAccount/?chargeable=${hasChargeable}`}>
								<Heading as="h1" fontSize="18px" >+ Link another account</Heading>
							</Link>
							<Heading as="h4" fontSize={16} marginBottom={3} marginTop={20}>Legal</Heading>
							<Link href={'https://www.flourishchange.com/privacy/'}><Paragraph>Privacy Policy</Paragraph></Link>
							<Link href={'https://www.flourishchange.com/terms-and-conditions/#top'}><Paragraph>Terms of Service</Paragraph></Link>

							<Heading as="h4" fontSize={16} marginBottom={3} marginTop={20}>Contact</Heading>
							<Link href="mailto:support@flourishchange.com" style={{marginBottom: 25}}><Paragraph>support@flourishchange.com</Paragraph></Link>
						</div>
						{profile_pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Loading...</Heading>
								</div>
							</div>
						</div>
						}
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	getUserProfile: PropTypes.func,
	setDonationAccount: PropTypes.func,
	accountList: PropTypes.array,
	setDonationType: PropTypes.func,
	profile_pending: PropTypes.bool,
	donor_type: PropTypes.number,
	profile_error: PropTypes.string,
	deleteAccount: PropTypes.func,
	bank_pending: PropTypes.bool,
	daily_donation: PropTypes.string,
	monthly_min_donation_limit: PropTypes.string,
	month_donation_limit: PropTypes.string,
	setMonthlyMax: PropTypes.func,
	setMonthlyMin: PropTypes.func,
	trackAccountForRoundups: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		donor_type: state.userState.donor_type,
		month_donation_limit: state.userState.month_donation_limit,
		daily_donation: state.userState.daily_donation,
		current_month_donations_sum: state.userState.current_month_donations_sum,
		zip_code: state.userState.zip_code,
		monthly_min_donation_limit: state.userState.monthly_min_donation_limit,
		accountList: state.userState.accountList,
		profile_pending: state.userState.profile_pending,
		profile_error: state.userState.profile_error,
		bank_pending: state.bankState.pending,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUserProfile: (token) => dispatch(getUserProfile(token)),
		setDonationAccount: (acc_id, value, token) => dispatch(setDonationAccount(acc_id, value, token)),
		setDonationType: (params, token) => dispatch(setDonationType(params, token)),
		deleteAccount: (account_id, token, successCallback) => dispatch(deleteAccount(account_id, token, successCallback)),
		setMonthlyMax: (max, token) => dispatch(setMonthlyMax(max, token)),
		setMonthlyMin: (min, token) => dispatch(setMonthlyMin(min, token)),
		trackAccountForRoundups: (acc_id, tracked, token) => dispatch(trackAccountForRoundups(acc_id, tracked, token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
